import { useRoutes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import { MatxTheme } from './components'
// ALL CONTEXTS
import { AuthProvider } from './contexts/JWTAuthContext'
import SettingsProvider from './contexts/SettingsContext'
// ROUTES
import routes from './routes'
// FAKE SERVER
import '../fake-db'
import { SnackbarProvider } from './snakebarContext'

import io from 'socket.io-client'
import { useEffect } from 'react'

// const socket = io(process.env.REACT_APP_API_URL, {
//   transports: ['websocket', 'polling']
// }) // Replace with your server's URL if different

export default function App() {
  const content = useRoutes(routes)

  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  )
}
